import axios from "axios";
import config from "../../config.json";
const fetchGetAPI = async (endPoint = "") => {
  try {
    const response = await axios.get(`${config.APIURL}/${endPoint}`);
    return response?.data;
  } catch (error) {
    console.error("error: ", error);
    return {
      status: "error",
      message: "Something went wrong. Please try again later.",
    };
  }
};
const fetchPostAPI = async (endPoint = "", data = {}) => {
  try {
    const response = await axios.post(`${config.APIURL}/${endPoint}`, data);
    return response?.data;
  } catch (error) {
    console.error("error: ", error);
    return {
      status: "error",
      message: "Something went wrong. Please try again later.",
    };
  }
};
export { fetchGetAPI, fetchPostAPI };
