import React, { useState, useEffect, useCallback } from "react";
import {
  AlphaCard,
  DataTable,
  Layout,
  Text,
  Button,
  Box,
  VerticalStack,
  Spinner,
  Icon,
  TextField,
  Toast,
} from "@shopify/polaris";
import {
  ChevronDownMinor,
  ChevronUpMinor,
  ClipboardMinor,
} from "@shopify/polaris-icons";
import axios from "axios";
import config from "../../config.json";

const PendingInvoice = () => {
  const [data, setData] = useState([]);
  const [expandedStores, setExpandedStores] = useState({});
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        const response = await axios.get(
          `${config.APIURL}/admin/pendingInvoiceOneTimePay`
        );

        if (response.data.status === "success" && response.data.data) {
          setData(response.data.data);
        } else {
          setData([]);
        }
      } catch (error) {
        console.error("Error fetching invoice data:", error);
        setData([]);
      } finally {
        setLoading(false);
      }
    };

    fetchInvoices();
  }, []);

  const toggleExpand = (index) => {
    setExpandedStores((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const handleSearchChange = (value) => {
    setSearchTerm(value.toLowerCase());
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setShowToast(true);
    setToastMessage("URL copied to clipboard");
  };

  const handleToastDismiss = useCallback(() => setShowToast(false), []);

  const updatePaymentStatus = async (shopUrl, invoice_no, status) => {
    try {
      const response = await axios.post(
        `${config.APIURL}/admin/markAsPainOneTime`,
        null,
        {
          params: { shopUrl, invoice_no, status },
        }
      );

      if (response.data.status === "success") {
        setToastMessage(
          `Invoice marked as ${status === "paid" ? "Paid" : "Unpaid"}`
        );
        setShowToast(true);
        setData((prevData) =>
          prevData.map((store) =>
            store.MyShopifyDomain === shopUrl
              ? {
                  ...store,
                  invoices: store.invoices.map((invoice) =>
                    invoice.invoice_no === invoice_no
                      ? { ...invoice, payment_Status: status }
                      : invoice
                  ),
                }
              : store
          )
        );
      } else {
        console.error(response.data.message);
      }
    } catch (error) {
      console.error("Error updating payment status:", error);
      setToastMessage("Failed to update payment status");
      setShowToast(true);
    }
  };

  const filteredData = data.filter((store) =>
    store.MyShopifyDomain.toLowerCase().includes(searchTerm)
  );

  const overallTotalAmount = data.reduce((total, store) => {
    return (
      total + store.invoices.reduce((sum, invoice) => sum + invoice.price, 0)
    );
  }, 0);

  const totalClients = filteredData.length;

  if (loading) {
    return <Spinner accessibilityLabel="Loading invoices" size="large" />;
  }

  if (data.length === 0) {
    return (
      <Text alignment="center" variant="bodyMd">
        No pending invoices available.
      </Text>
    );
  }

  return (
    <>
      <Box
        padding="4"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Text alignment="center" fontWeight="bold" variant="headingLg">
          Pending Invoices For One Time Pay
        </Text>
        <Text fontWeight="bold" variant="headingMd">
          Overall Total: ${overallTotalAmount.toFixed(2)}
        </Text>
        <Text fontWeight="bold" variant="headingMd">
          Total Clients: {totalClients}
        </Text>
      </Box>

      <Layout>
        <Layout.Section>
          <Box padding="4">
            <TextField
              label="Search by Store Domain"
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder="Enter store domain"
              clearButton
              onClearButtonClick={() => setSearchTerm("")}
            />
          </Box>
        </Layout.Section>

        {filteredData.length === 0 ? (
          <Layout.Section>
            <Text alignment="center" variant="bodyMd">
              No matching stores found.
            </Text>
          </Layout.Section>
        ) : (
          filteredData.map((store, index) => {
            const storeUrl = `https://${store.MyShopifyDomain}`;
            const totalPrice = store.invoices.reduce(
              (sum, invoice) => sum + invoice.price,
              0
            );
            const rows = store.invoices.map((invoice) => [
              invoice.invoice_no,
              `$${invoice.price.toFixed(2)}`,
              `${invoice.description}`,
              <Button
                onClick={() =>
                  updatePaymentStatus(
                    store.MyShopifyDomain,
                    invoice.invoice_no,
                    "paid"
                  )
                }
                disabled={invoice.payment_Status === "paid"}
              >
                Paid
              </Button>,
              <Button
                onClick={() =>
                  updatePaymentStatus(
                    store.MyShopifyDomain,
                    invoice.invoice_no,
                    "unpaid"
                  )
                }
                disabled={invoice.payment_Status === "unpaid"}
              >
                Unpaid
              </Button>,
            ]);

            return (
              <Layout.Section key={index}>
                <Box
                  padding="4"
                  borderRadius="3"
                  shadow="sm"
                  background="subdued"
                >
                  <VerticalStack spacing="tight">
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Button
                        plain
                        icon={ClipboardMinor}
                        onClick={() => window.open(storeUrl, "_blank")}
                        accessibilityLabel="Preview store URL"
                      >
                        Preview URL
                      </Button>

                      <Button
                        plain
                        fullWidth
                        onClick={() => toggleExpand(index)}
                        ariaExpanded={expandedStores[index] ? "true" : "false"}
                        style={{
                          padding: "15px",
                          textAlign: "left",
                          fontWeight: "bold",
                          color: "#0070f3",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          backgroundColor: "#f4f6f8",
                          borderRadius: "4px",
                        }}
                      >
                        <Text
                          as="h3"
                          variant="headingMd"
                          alignment="left"
                          fontWeight="bold"
                        >
                          {store.MyShopifyDomain}
                        </Text>
                        <Icon
                          source={
                            expandedStores[index]
                              ? ChevronUpMinor
                              : ChevronDownMinor
                          }
                          color="base"
                        />
                      </Button>
                    </Box>

                    {expandedStores[index] && (
                      <AlphaCard sectioned>
                        <DataTable
                          columnContentTypes={[
                            "text",
                            "numeric",
                            "text",
                            "text",
                            "text",
                          ]}
                          headings={[
                            "Invoice No",
                            "Price",
                            "Description",
                            "Mark as Paid",
                            "Mark as Unpaid",
                          ]}
                          rows={rows}
                          footerContent={
                            <Text variant="bodyLg" fontWeight="bold">
                              Total: ${totalPrice.toFixed(2)}
                            </Text>
                          }
                        />
                      </AlphaCard>
                    )}
                  </VerticalStack>
                </Box>
              </Layout.Section>
            );
          })
        )}
      </Layout>

      {showToast && (
        <Toast content={toastMessage} onDismiss={handleToastDismiss} />
      )}
    </>
  );
};

export default PendingInvoice;
