import { AlphaCard, Button, ButtonGroup, HorizontalGrid, Page, Select, Text, TextField } from "@shopify/polaris";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { AuthContext } from "../../../../ContextApi/AuthContext";
import config from "../../../../config.json";
import axios from "axios";
import UseToast from "../../../../Hooks/UseToast";
import OneTimePlanComponent from "./components/OneTimePlan";
import CustomPlan from "./components/CustomPlan";

const OneTimePlan = () => {
  //#region --Initialization --
  const navigate = useNavigate();
  //#endregion
  //#region --React State
  const [isOneTimePlan, setIsOneTimePlan] = useState(true);
  //#endregion
  //#region -- Markups --
  const back_redirect = () => {
    navigate(`/ListingPage`);
  };

  const titleWithBackButton = (
    <div style={{ display: "flex" }}>
      <div style={{ height: "35px", width: "35px", cursor: "pointer" }} onClick={back_redirect}>
        <svg
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
          style={{ padding: "5px", border: "1px solid lightgray" }}
        >
          <path
            d="M19 9H3.661l5.997-5.246a1 1 0 00-1.316-1.506l-8 7c-.008.007-.011.018-.019.025a.975.975 0 00-.177.24c-.018.03-.045.054-.059.087a.975.975 0 000 .802c.014.033.041.057.059.088.05.087.104.17.177.239.008.007.011.018.019.025l8 7a.996.996 0 001.411-.095 1 1 0 00-.095-1.411L3.661 11H19a1 1 0 000-2z"
            fill="#5C5F62"
          />
        </svg>
      </div>
      <span style={{ margin: "0 10px", fontWeight: "bold" }}>Plan Page</span>
    </div>
  );
  //#endregion
  return (
    <Page
      title={titleWithBackButton}
      primaryAction={
        <ButtonGroup segmented>
          <Button pressed={isOneTimePlan} onClick={() => setIsOneTimePlan(true)}>
            One Time Plan
          </Button>
          <Button pressed={!isOneTimePlan} onClick={() => setIsOneTimePlan(false)}>
            Customize Plan
          </Button>
        </ButtonGroup>
      }
    >
      {isOneTimePlan ? <OneTimePlanComponent /> : <CustomPlan setIsOneTimePlan={setIsOneTimePlan} />}
    </Page>
  );
};

export default OneTimePlan;
