import { Badge, Button, DataTable, LegacyCard, LegacyStack, Text } from "@shopify/polaris";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchGetAPI, fetchPostAPI } from "../../../../../utils/fetcher/fetcher";
import { AuthContext } from "../../../../../ContextApi/AuthContext";
import UseToast from "../../../../../Hooks/UseToast";

const OneTimePlanComponent = () => {
  //#region --Initialization --
  const navigate = useNavigate();
  const { sellerDetails } = useContext(AuthContext);
  const { MyShopifyDomain } = sellerDetails;
  const { showToast, toastMarkup } = UseToast();
  //#endregion
  //#region --React State --
  const [listData, setListData] = useState([]);
  //#endregion
  //#region -- Functions --
  const getPlanList = async () => {
    const response = await fetchGetAPI(`api/oneTimePayPrice?Shop=${MyShopifyDomain}`);
    if (response?.PlanPricingData) {
      setListData(response?.PlanPricingData);
    } else if (response?.status === "error") {
      showToast(response?.message);
    }
  };
  const deletePlan = async (invoice_no) => {
    const response = await fetchPostAPI("api/removeOneTimeCharge", {
      invoice_number: invoice_no,
      shop: MyShopifyDomain,
    });
    if (response?.status === "success") {
      showToast(response?.message);
      getPlanList();
    } else if (response?.status === "error") {
      showToast(response?.message);
    }
  };
  const rows = listData.map((item) => {
    return [
      <Text as="span" variant="bodyMd" fontWeight="regular">
        {item?.invoice_no}
      </Text>,
      <Text as="span" variant="bodyMd" fontWeight="regular" breakWord>
        {item?.description}
      </Text>,
      <Text as="span" variant="bodyMd" fontWeight="regular">
        {item?.payment_type}
      </Text>,
      <Text as="span" variant="bodyMd" fontWeight="regular">
        {item?.price}
      </Text>,
      <LegacyStack distribution="center">
        <Badge status={item?.payment_Status ? "critical" : "success"}>
          {item?.payment_Status ? "Unsubscribed" : "Subscribed"}
        </Badge>
      </LegacyStack>,
      <Button
        primary
        size="micro"
        onClick={() => {
          deletePlan(item?.invoice_no);
        }}
      >
        Delete
      </Button>,
    ];
  });
  //#endregion
  useEffect(() => {
    getPlanList();
  }, []);
  console.log(listData);
  return (
    <LegacyStack vertical>
      <LegacyStack distribution="trailing">
        <Button primary onClick={() => navigate("/OneTimePlan/Create")}>
          Create new plan
        </Button>
      </LegacyStack>
      <LegacyCard>
        {rows.length !== 0 ? (
          <DataTable
            columnContentTypes={["text", "text", "text", "text", "text"]}
            headings={[
              <Text as="h1" variant="headingMd" fontWeight="medium">
                Invoice No.
              </Text>,
              <Text as="h1" variant="headingMd" fontWeight="medium">
                Description
              </Text>,
              <Text as="h1" variant="headingMd" fontWeight="medium">
                Type
              </Text>,
              <Text as="h1" variant="headingMd" fontWeight="medium">
                Price
              </Text>,
              <Text as="h1" variant="headingMd" fontWeight="medium">
                Status
              </Text>,
              <Text as="h1" variant="headingMd" fontWeight="medium">
                Action
              </Text>,
            ]}
            rows={rows}
            verticalAlign="middle"
          />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "300px",
              gap: "10px",
            }}
          >
            <Text>No One Time Plan found.</Text>
            <Button primary onClick={() => navigate("/OneTimePlan/Create")}>
              Create new plan
            </Button>
          </div>
        )}
      </LegacyCard>
      {toastMarkup}
    </LegacyStack>
  );
};

export default OneTimePlanComponent;
