import {
  AlphaCard,
  Badge,
  Button,
  DataTable,
  Layout,
  LegacyCard,
  LegacyStack,
  Text,
  TextField,
  List
} from "@shopify/polaris";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchGetAPI, fetchPostAPI } from "../../../../../utils/fetcher/fetcher";
import { AuthContext } from "../../../../../ContextApi/AuthContext";
import UseToast from "../../../../../Hooks/UseToast";

const CustomPlan = ({ setIsOneTimePlan }) => {
  //#region --Initialization --
  const navigate = useNavigate();
  const { sellerDetails } = useContext(AuthContext);
  const { MyShopifyDomain } = sellerDetails;
  const { showToast, toastMarkup } = UseToast();
  //#endregion
  //#region --React State --
  const [listData, setListData] = useState([]);
  const [monthlyPrice, setMonthlyPrice] = useState("");
  const [yearlyPrice, setYearlyPrice] = useState("");
  const [fields, setFields] = useState(['']); // Initial state with two empty fields

  //#endregion
  //#region -- Functions --
  const getPlanList = async () => {
    const response = await fetchGetAPI(`api/customizationPlan?Shop=${MyShopifyDomain}`);
    if (response?.PlanPricingData) {
      setListData(response?.PlanPricingData);
    } else if (response?.status === "error") {
      showToast(response?.message);
    }
  };

  const handleAddField = () => {
    setFields([...fields, '']); // Add a new empty string to the array
  };

  const handleRemoveField = (index) => {
    if (fields.length > 1) {
      // Only remove the field if there's more than 1 left
      setFields(fields.filter((_, i) => i !== index));
    }
  };

  const handleFieldChange = (value, index) => {
    const updatedFields = [...fields];
    updatedFields[index] = value; // Update the field value at the specific index
    setFields(updatedFields);
  };

  function checkArrayValues(arr) {
    // Iterate through the array and check if any value is null or an empty string
    for (let value of arr) {
      if (value === null || value === '') {
        return false; // Return false if any value is null or empty
      }
    }
    return true; // Return true if all values are non-empty and non-null
  }

  
  const handleSubmit = async () => {
    const data = {
      monthlyPrice: monthlyPrice,
      yearlyPrice: yearlyPrice,
      shop: MyShopifyDomain,
      planMessageList: fields,
    };
    let isError = false;
    if (monthlyPrice.length === 0) {
      isError = true;
      showToast("Monthly price is required.", true);
      return;
    }
    if (yearlyPrice.length === 0) {
      isError = true;
      showToast("Yearly price is required.", true);
      return;
    }
    if(!checkArrayValues(fields)){
      isError = true;
      showToast("Please Fill Requirement Message .", true);
      return;
    }
    if (!isError) {
      const res = await fetchPostAPI("api/createMonYearCustomPlan", data);
      if (res?.status === "success") {
        getPlanList();
        showToast(res?.message);
      } else if (res?.status === "error") {
        showToast(res?.message);
      }
    }
  };

  //#endregion
  useEffect(() => {
    getPlanList();
  }, []);
  return (
    <Layout>
      <Layout.Section oneThird>
        <LegacyStack vertical>
          <AlphaCard>
            <Text as="h4" variant="headingMd" fontWeight="semibold">
              Monthly Price:
            </Text>
            <TextField
              autoComplete="off"
              autoFocus="off"
              placeholder="Enter monthly price"
              value={monthlyPrice}
              onChange={setMonthlyPrice}
            />
          </AlphaCard>
          <AlphaCard>
            <Text as="h4" variant="headingMd" fontWeight="semibold">
              Yearly Price:
            </Text>
            <TextField
              autoComplete="off"
              autoFocus="off"
              placeholder="Enter yearly price"
              value={yearlyPrice}
              onChange={setYearlyPrice}
            />
          </AlphaCard>
          <AlphaCard>
            <Text as="h4" variant="headingMd" fontWeight="semibold">
              Plan Message:
            </Text>

            {fields.map((field, index) => (
              <div key={index} style={{ marginBottom: '10px' }}>
                <TextField
                  autoComplete="off"
                  placeholder={`Requirement Message ${index + 1}`}
                  value={field}
                  onChange={(value) => handleFieldChange(value, index)} // Update field value
                  connectedRight={<Button onClick={() => handleRemoveField(index)} destructive>
                    Remove
                  </Button>}
                />

              </div>
            ))}

            <Button onClick={handleAddField}>Add Field</Button>
          </AlphaCard>
          <Button primary onClick={handleSubmit}>
            Submit
          </Button>
        </LegacyStack>
      </Layout.Section>
      <Layout.Section secondary>
        <AlphaCard>
          <Text as="h4" variant="headingMd" fontWeight="semibold">
            Current Plan
          </Text>
          <LegacyStack vertical>
            <Text as="h4" variant="headingSm" fontWeight="semibold">
              Monthly Price : {`${listData?.customizationPlanPriceMon ?? "No existing price"}`}
            </Text>
            <Text as="h4" variant="headingSm" fontWeight="semibold">
              Yearly Price : {`${listData?.customizationPlanPriceYear ?? "No existing price"}`}
            </Text>

            {/* Display fields using Polaris List */}
            <List type="bullet" gap="extraTight">
              {listData?.customizationPlanList?.map((field, index) => (
                <List.Item key={index}>
                  {field}
                </List.Item>
              ))}
            </List>
          </LegacyStack>
        </AlphaCard>
      </Layout.Section>
      {toastMarkup}
    </Layout>
  );
};

export default CustomPlan;
