import React, { useState, useCallback, useRef, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Dashboard from "../components/Dashboard/Dashboard";
import ListingPage from "../components/listingPage/LIstingPage";
import DetailsPage from "../components/sellerStoreDetailsPage/DetailsPage";
import { useLocation } from "react-router-dom";
import { FormLayout, Frame, Modal, Navigation, Text, TextField, TopBar, Icon } from "@shopify/polaris";
import { HomeMajor, CustomersMajor, ExitMajor, SettingsMajor, DiscountsMajor, MetafieldsMajor, PlanMajor } from "@shopify/polaris-icons";
import AssetThemeList from "../components/AssetComponent/ThemeList";
import AssetExtensionCode from "../components/AssetComponent/ThemeExtensionCode";
import AssetsList from "../components/AssetComponent/AssetsList";
import EditAssetPage from "../components/AssetComponent/EditAssetPage";
import EditAssetThemeExtensionPage from "../components/AssetComponent/EditAssetThemeExtensionPage.js";
import GroupList from "../components/sellerStoreDetailsPage/CustomerCRUD/CustomerGroup/GroupList";
import CreateManual from "../components/sellerStoreDetailsPage/CustomerCRUD/CustomerGroup/CreateManual";
import CreateAutomatic from "../components/sellerStoreDetailsPage/CustomerCRUD/CustomerGroup/CreateAutomatic";
import CreateRule from "../components/sellerStoreDetailsPage/CustomerCRUD/Subtotal Discount Rule/CreateRule";
import UpdateAutomatic from "../components/sellerStoreDetailsPage/CustomerCRUD/CustomerGroup/UpdateAutomatic";
import UpdateManual from "../components/sellerStoreDetailsPage/CustomerCRUD/CustomerGroup/UpdateManual";
import Setting from "../components/sellerStoreDetailsPage/Setting Page/Setting";
import CreateQuantityRule from "../components/sellerStoreDetailsPage/CustomerCRUD/Subtotal Quantity/CreateQuantityRule";
import DisplaySetting from "../components/sellerStoreDetailsPage/Setting Page/DisplaySetting";
// for contexts

import { AuthContext } from "../ContextApi/AuthContext";
import CreateProductDiscount from "../components/sellerStoreDetailsPage/CustomerCRUD/Product Discount/CreateProductDiscount.js";
import CreateOrderDiscount from "../components/sellerStoreDetailsPage/CustomerCRUD/Order Discount/CreateOrderDiscount.js";
import DeleteMetafield from "../components/sellerStoreDetailsPage/CustomerCRUD/DeleteMetafield/DeleteMetafield.js";
import DeleteTierMetaField from "../components/sellerStoreDetailsPage/CustomerCRUD/DeleteMetafield/DeleteTierMetaField.js";
import OneTimePlan from "../components/sellerStoreDetailsPage/CustomerCRUD/Plan/OneTimePlan.js";
import CreateOTPPlan from "../components/sellerStoreDetailsPage/CustomerCRUD/Plan/components/CreateOTPPlan.js";

const AppRoutes = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const defaultState = useRef({
        emailFieldValue: "contact@anncode.com",
        nameFieldValue: "Anncode",
    });
    // checking store exist or not
    const { isStore, setIsStore, sellerDetails } = React.useContext(AuthContext);
    const { MyShopifyDomain } = sellerDetails;
    // console.log("isStore is",isStore)
    const [userMenuActive, setUserMenuActive] = useState(false);
    const [mobileNavigationActive, setMobileNavigationActive] = useState(false);

    const [storeName, setStoreName] = useState(defaultState.current.nameFieldValue);
    const [supportSubject, setSupportSubject] = useState("");
    const [supportMessage, setSupportMessage] = useState("");
    const handleSubjectChange = useCallback((value) => setSupportSubject(value), []);
    const handleMessageChange = useCallback((value) => setSupportMessage(value), []);
    const toggleUserMenuActive = useCallback(() => setUserMenuActive((userMenuActive) => !userMenuActive), []);
    const toggleMobileNavigationActive = useCallback(() => setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive), []);
    //! Login signup modal
    const [modalActive, setModalActive] = useState(false);
    const toggleModalActive = useCallback(() => setModalActive((modalActive) => !modalActive), []);

    const handleNavigateGroupList = () => {
        navigate(`/GroupList/${MyShopifyDomain}`);
    }

    const handleNavigateCreateRule = () => {
        navigate(`/CreateRule/${MyShopifyDomain}`);
    }

    const handleNavigateCreateQuantityRule = () => {
        navigate(`/CreateQuantityRule/${MyShopifyDomain}`);
    }
    const handleNavigateProductDiscount = () => {
        navigate(`/CreateProductDiscount/${MyShopifyDomain}`);
    }
    const handleNavigateOrderDiscount = () => {
        navigate(`/CreateOrderDiscount/${MyShopifyDomain}`);
    }
    const handleNavigateDeleteMetafield = () => {
        navigate(`/DeleteMetafield/${MyShopifyDomain}`);
    }
    const handleNavigateDeleteTierMetafield = () => {
        navigate(`/DeletetierMetafield/${MyShopifyDomain}`);
    }

    const handleNavigatePlan = () => {
        navigate(`/OneTimePlan/${MyShopifyDomain}`);
    }
    const handleNavigateSetting = () => {
        navigate(`/Setting/${MyShopifyDomain}`);
    }

    const LogoutUser = () => {
        localStorage.removeItem("ctd-admin-token");
        alert("Logged out successfully");
        window.location.reload(false);
        window.location = "/";
        // window.location = "/login";
    };

    const userMenuMarkup = <TopBar.UserMenu name="AnnPro" detail={storeName} initials="A" open={userMenuActive} onToggle={toggleUserMenuActive} />;
    const topBarMarkup = <TopBar showNavigationToggle userMenu={userMenuMarkup} onNavigationToggle={toggleMobileNavigationActive} />;
    const navigationMarkup = (
        <Navigation location={location.pathname}>
            <Navigation.Section
                items={[
                    {
                        label: "Dashboard",
                        icon: HomeMajor,
                        onClick: () => {
                            navigate("/");
                        },
                        selected: "/" === location.pathname,
                    },
                    {
                        label: "Seller",
                        icon: CustomersMajor,
                        onClick: () => {
                            navigate("/ListingPage");
                        },
                        selected: "/ListingPage" === location.pathname,
                    },
                    // {
                    //     label: "Create Customer Group",
                    //     disabled: !isStore,
                    //     icon:TeamMajor,
                    //     onClick: handleNavigateGroupList,
                    //     selected: `/GroupList/${MyShopifyDomain}` === location.pathname,
                    // },
                    {
                        label: "Create Quantity Discount",
                        disabled: !isStore,
                        icon: DiscountsMajor,
                        onClick: handleNavigateCreateQuantityRule,
                        selected: `/CreateQuantityRule/${MyShopifyDomain}` === location.pathname,
                    },
                    {
                        label: "Create Subtotal Discount",
                        disabled: !isStore,
                        icon: DiscountsMajor,
                        onClick: handleNavigateCreateRule,
                        selected: `/CreateRule/${MyShopifyDomain}` === location.pathname,
                    },
                    {
                        label: "Create Product Discount",
                        disabled: !isStore,
                        icon: DiscountsMajor,
                        onClick: handleNavigateProductDiscount,
                        selected: `/CreateProductDiscount/${MyShopifyDomain}` === location.pathname,
                    },
                    {
                        label: "Create Order Discount",
                        disabled: !isStore,
                        icon: DiscountsMajor,
                        onClick: handleNavigateOrderDiscount,
                        selected: `/CreateOrderDiscount/${MyShopifyDomain}` === location.pathname,
                    },
                    {
                        label: "Delete Customer Metafield",
                        disabled: !isStore,
                        icon: MetafieldsMajor,
                        onClick: handleNavigateDeleteMetafield,
                        selected: `/DeleteMetafield/${MyShopifyDomain}` === location.pathname,
                    },
                    {
                        label: "Delete tier Metafield",
                        disabled: !isStore,
                        icon: MetafieldsMajor,
                        onClick: handleNavigateDeleteTierMetafield,
                        selected: `/DeleteTierMetafield/${MyShopifyDomain}` === location.pathname,
                    },

                    {
                        label: "Plan",
                        icon: PlanMajor,
                        disabled: !isStore,
                        onClick: handleNavigatePlan,
                        selected: `/OneTimePlan/${MyShopifyDomain}` === location.pathname,
                    },
                    {
                        label: "Setting",
                        icon: SettingsMajor,
                        disabled: !isStore,
                        onClick: handleNavigateSetting,
                        selected: (`/Setting/${MyShopifyDomain}` === location.pathname || location.pathname === `/DisplaySetting/${MyShopifyDomain}`),
                    },
                    {
                        label: "Logout",
                        icon: ExitMajor,
                        onClick: () => LogoutUser(),
                        // selected: "/ListingPage" === location.pathname,
                    }
                ]}
                rollup={{
                    after: 3,
                    view: "view",
                    hide: "hide",
                    activePath: "#",
                }}
            />
        </Navigation>
    );

    const modalMarkup = (
        <Modal
            open={modalActive}
            onClose={toggleModalActive}
            title="Contact support"
            primaryAction={{ content: "Send", onAction: toggleModalActive }}
        >
            <Modal.Section>
                <FormLayout>
                    <TextField label="Subject" value={supportSubject} onChange={handleSubjectChange} autoComplete="off" />
                    <TextField label="Message" value={supportMessage} onChange={handleMessageChange} autoComplete="off" multiline />
                </FormLayout>
            </Modal.Section>
        </Modal>
    );

    const logo = {
        width: 50,
        topBarSource: "./company-logo.png",
        contextualSaveBarSource: "./company-logo.png",
        url: "#",
        accessibilityLabel: "AnnCode solution",
    };

    return (
        <React.Fragment>
            <Frame logo={logo} topBar={topBarMarkup} navigation={navigationMarkup} showMobileNavigation={mobileNavigationActive} onNavigationDismiss={toggleMobileNavigationActive}>
                <Routes>
                    <Route path="*" element={<Dashboard />} />
                    <Route path="ListingPage" element={<ListingPage />} />
                    <Route path="DetailsPage" element={<DetailsPage />} />
                    <Route path="AssetThemeExtesntion" element={<AssetThemeList />} />
                    <Route path="AssetThemeExtesntionFiles" element={<AssetExtensionCode />} />
                    {/* <Route path="AssetsList" element={<AssetsList />} /> */}
                    {/* <Route path="EditAssetPage" element={<EditAssetPage />} /> */}
                    <Route path="EditAssetThemeExtensionPage" element={<EditAssetThemeExtensionPage />} />
                    {/* Routesto create sutomer group and rules for discount routes  */}
                    <Route path="GroupList/:myShopifyDomain" element={<GroupList />} />
                    <Route path="CreateManual/:myShopifyDomain" element={<CreateManual />} />
                    <Route path="CreateAutomatic/:myShopifyDomain" element={<CreateAutomatic />} />
                    <Route path="UpdateManual/:myShopifyDomain/:id" element={<UpdateManual />} />
                    <Route path="UpdateAutomatic/:myShopifyDomain/:id/:tag_name" element={<UpdateAutomatic />} />
                    <Route path="CreateRule/:myShopifyDomain" element={<CreateRule />} />
                    <Route path="CreateQuantityRule/:myShopifyDomain" element={<CreateQuantityRule />} />
                    <Route path="CreateProductDiscount/:myShopifyDomain" element={<CreateProductDiscount />} />
                    <Route path="CreateOrderDiscount/:myShopifyDomain" element={<CreateOrderDiscount />} />
                    <Route path="DeleteMetafield/:myShopifyDomain" element={<DeleteMetafield />} />
                    <Route path="DeleteTierMetafield/:myShopifyDomain" element={<DeleteTierMetaField />} />
                    <Route path="OneTimePlan/:myShopifyDomain" element={<OneTimePlan />} />
                    <Route path="OneTimePlan/Create" element={<CreateOTPPlan />} />
                    <Route path="Setting/:myShopifyDomain" element={<Setting />} />
                    <Route path="DisplaySetting/:myShopifyDomain" element={<DisplaySetting />} />
                </Routes>
                {modalMarkup}
            </Frame>
        </React.Fragment>
    );
};

export default AppRoutes;
