import { AlphaCard, Button, Layout, LegacyStack, Page, Select, Text, TextField } from "@shopify/polaris";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../../../ContextApi/AuthContext";
import UseToast from "../../../../../Hooks/UseToast";
import { fetchPostAPI } from "../../../../../utils/fetcher/fetcher";
const CreateOTPPlan = () => {
  //#region --Initialization --
  const { sellerDetails } = useContext(AuthContext);
  const { MyShopifyDomain } = sellerDetails;
  const navigate = useNavigate();
  const { showToast, toastMarkup } = UseToast();
  //#endregion
  //#region -- React States --
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  //#endregion

  //#region -- functions --
  const handleSubmit = async () => {
    const data = {
      invoice_number: invoiceNumber,
      description: description,
      price: price,
      shop: MyShopifyDomain,
    };
    let isError = false;
    console.log("invoiceNumber: ", invoiceNumber.length);
    if (invoiceNumber.length === 0) {
      isError = true;
      showToast("Please enter invoice number", true);
      return;
    }
    if (description.length === 0) {
      isError = true;
      showToast("Please enter description", true);
      return;
    }
    if (price.length === 0) {
      isError = true;
      showToast("Please enter price", true);
      return;
    }
    if (!isError) {
      const res = await fetchPostAPI("api/createOneTimeCharge", data);
      console.log("res: ", res);
      if (res?.status === "success") {
        showToast(res?.message);
      }
      setTimeout(() => {
        navigate(`/OneTimePlan/${MyShopifyDomain}`);
      }, 3000);
    }
  };
  //#endregion

  //#region -- Markups --
  const back_redirect = () => {
    navigate(`/OneTimePlan/${MyShopifyDomain}`);
  };

  const titleWithBackButton = (
    <div style={{ display: "flex" }}>
      <div style={{ height: "35px", width: "35px", cursor: "pointer" }} onClick={back_redirect}>
        <svg
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
          style={{ padding: "5px", border: "1px solid lightgray" }}
        >
          <path
            d="M19 9H3.661l5.997-5.246a1 1 0 00-1.316-1.506l-8 7c-.008.007-.011.018-.019.025a.975.975 0 00-.177.24c-.018.03-.045.054-.059.087a.975.975 0 000 .802c.014.033.041.057.059.088.05.087.104.17.177.239.008.007.011.018.019.025l8 7a.996.996 0 001.411-.095 1 1 0 00-.095-1.411L3.661 11H19a1 1 0 000-2z"
            fill="#5C5F62"
          />
        </svg>
      </div>
      <span style={{ margin: "0 10px", fontWeight: "bold" }}>Create new plan</span>
    </div>
  );
  //#endregion

  return (
    <Page title={titleWithBackButton}>
      <Layout>
        <Layout.Section oneThird>
          <LegacyStack vertical>
            <AlphaCard>
              <Text as="h4" variant="headingMd" fontWeight="semibold">
                Invoice Number:
              </Text>
              <TextField
                autoComplete="off"
                autoFocus="off"
                placeholder="Enter invoice number"
                value={invoiceNumber}
                onChange={setInvoiceNumber}
              />
            </AlphaCard>
            <AlphaCard>
              <Text as="h4" variant="headingMd" fontWeight="semibold">
                Description:
              </Text>
              <TextField
                autoComplete="off"
                autoFocus="off"
                placeholder="Enter description"
                value={description}
                onChange={setDescription}
              />
            </AlphaCard>
            <AlphaCard>
              <Text as="h4" variant="headingMd" fontWeight="semibold">
                Type:
              </Text>
              <Select options={[{ label: "One-Time", value: "One-Time" }]} disabled />
            </AlphaCard>
            <AlphaCard>
              <Text as="h4" variant="headingMd" fontWeight="semibold">
                Price:
              </Text>
              <TextField
                autoComplete="off"
                autoFocus="off"
                type="number"
                placeholder="Enter Price"
                value={price}
                onChange={setPrice}
              />
            </AlphaCard>
            <Button primary onClick={handleSubmit}>
              Submit
            </Button>
          </LegacyStack>
        </Layout.Section>
        <Layout.Section secondary></Layout.Section>
      </Layout>
      {toastMarkup}
    </Page>
  );
};

export default CreateOTPPlan;
